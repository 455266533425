<script setup lang="ts">
import { RightArrowIcon } from '@glow/shared/components/icons';
import { graphql } from '@/gql';
import { useQuery } from '@urql/vue';
import { formatMasterCount } from '@/features/masters';
import { formatCompanyCount } from '@/features/companies';
import { useRoutes } from '@glow/shared/lib/routes';

const { getUrl } = useRoutes();
const { data: queryData, fetching } = useQuery({
  query: graphql(/* GraphQL */ `
    query AppLayoutCatalogMenu_SpecializationsQuery {
      specializations {
        id
        slug
        name
        masterCount
        companyCount
        services {
          id
          slug
          name
        }
      }
    }
  `),
});
const specializations = toRef(() => queryData.value?.specializations);
const selectedSpecializationId = ref<number>();
const selectedSpecialization = computed(() =>
  specializations.value?.find(
    (specialization) => specialization.id === selectedSpecializationId.value
  )
);
</script>

<template>
  <div class="menu">
    <AppLoader v-if="fetching" />
    <div class="section specializations">
      <AppButton
        class="specializations__item"
        :to="
          getUrl('partnerCatalog', {
            city: 'moscow',
            type: 'master',
          })
        "
        color="secondary"
        variant="link"
        text-align="start"
      >
        Все специальности
      </AppButton>
      <AppButton
        v-for="specialization in specializations"
        :key="specialization.id"
        class="specializations__item"
        :color="
          selectedSpecializationId === specialization.id
            ? 'primary'
            : 'secondary'
        "
        fluid
        variant="link"
        text-align="start"
        :icon-end="
          selectedSpecializationId === specialization.id
            ? RightArrowIcon
            : undefined
        "
        icon-end-color="primary"
        @click="selectedSpecializationId = specialization.id"
      >
        {{ specialization.name }}
      </AppButton>
    </div>
    <div v-if="selectedSpecialization" class="services section">
      <NuxtLink
        :to="
          getUrl('services', {
            specialization: selectedSpecialization.slug,
          })
        "
      >
        <h2 class="services__title text-dark">
          {{ selectedSpecialization.name }}
        </h2>
        <div class="services__partner-count">
          {{ formatMasterCount(selectedSpecialization.masterCount) }} /
          {{ formatCompanyCount(selectedSpecialization.companyCount) }}
        </div>
      </NuxtLink>
      <div class="services__list">
        <AppButton
          v-for="service in selectedSpecialization.services"
          :key="service.id"
          :to="
            getUrl('partnerCatalog', {
              type: 'master',
              city: 'moscow',
              specialization: selectedSpecialization.slug,
              service: service.slug,
            })
          "
          variant="link"
          text-align="start"
          color="secondary"
        >
          {{ service.name }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.menu {
  display: flex;
  flex-wrap: nowrap;
  gap: 32px;
}

.section {
  min-width: 300px;
  position: relative;

  &:not(:first-child) {
    padding-left: 32px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      border-left: 1px solid #ececec;
      height: 100%;
    }
  }
}

.specializations {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    &:not(:last-child)::after {
      content: '';
      display: block;
      border-top: 1px solid #ececec;
      width: 100%;
      margin-top: 12px;
    }
  }
}

.services {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__title {
    margin: 0;
  }

  &__partner-count {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--app-color-black-secondary);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
